exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-my-site-js": () => import("./../../../src/pages/about-my-site.js" /* webpackChunkName: "component---src-pages-about-my-site-js" */),
  "component---src-pages-anime-js": () => import("./../../../src/pages/anime.js" /* webpackChunkName: "component---src-pages-anime-js" */),
  "component---src-pages-coding-index-js": () => import("./../../../src/pages/coding/index.js" /* webpackChunkName: "component---src-pages-coding-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mini-games-tic-tac-toe-js": () => import("./../../../src/pages/mini-games/tic-tac-toe.js" /* webpackChunkName: "component---src-pages-mini-games-tic-tac-toe-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-projects-shabotto-index-js": () => import("./../../../src/pages/projects/shabotto/index.js" /* webpackChunkName: "component---src-pages-projects-shabotto-index-js" */),
  "component---src-pages-projects-smis-extension-index-js": () => import("./../../../src/pages/projects/smis-extension/index.js" /* webpackChunkName: "component---src-pages-projects-smis-extension-index-js" */),
  "component---src-pages-tools-number-converter-js": () => import("./../../../src/pages/tools/number-converter.js" /* webpackChunkName: "component---src-pages-tools-number-converter-js" */),
  "component---src-pages-tools-password-generator-js": () => import("./../../../src/pages/tools/password-generator.js" /* webpackChunkName: "component---src-pages-tools-password-generator-js" */),
  "component---src-pages-tools-qr-code-generator-index-js": () => import("./../../../src/pages/tools/qr-code-generator/index.js" /* webpackChunkName: "component---src-pages-tools-qr-code-generator-index-js" */),
  "component---src-pages-tools-tiktok-image-scraper-js": () => import("./../../../src/pages/tools/tiktok-image-scraper.js" /* webpackChunkName: "component---src-pages-tools-tiktok-image-scraper-js" */),
  "component---src-templates-link-tree-js": () => import("./../../../src/templates/linkTree.js" /* webpackChunkName: "component---src-templates-link-tree-js" */)
}

